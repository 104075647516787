import * as Sentry from '@sentry/browser';
import cookie from 'react-cookie';
import request, { removeSessionToken, startNewSession } from 'utils/request';
import { cookies } from 'config';

// Auth cookie domain
const domain =
	process.env.NODE_ENV === 'test'
		? null :
		'.typography.com';
/**
 * Sets the current access token cookie
 * @param {String} accessToken
 */
export function setAccessToken(accessToken, options) {
	cookie.save(cookies.accessToken, accessToken, {
		...options,
		domain,
		path: '/',
		sameSite: 'lax'
	});
}

/**
 * Remove the current access token cookie
 * @return void
 */
export function removeAccessToken() {
	cookie.remove(cookies.accessToken, {
		domain,
		secure: process.env.NODE_ENV === 'production',
		path: '/'
	});
}

/**
 * Removes the e-mail token set at login
 * @return  {Void}
 */
export function removeEmailToken() {
	cookie.remove(cookies.userEmail, {
		domain,
		secure: process.env.NODE_ENV === 'production',
		path: '/'
	});
}


/**
 * Returns the current access token
 * @return {String} access token
 */
export function getAccessToken() {
	return cookie.load(cookies.accessToken, {
		domain,
		path: '/'
	});
}

/**
 * Checks whether an access token exists
 * @return {Boolean} 			flag indicating whether an access token exists
 */
export function isAuthenticated() {
	return !!getAccessToken();
}

/**
 * Check if reauthentication is required
 * @return {Boolean} flag indicating whether an reauthenticationq token exists
 */
export async function requiresReauthentication({ success, error }) {
	try {
		await request('/api/v1/re_authentication', {
			method: 'POST'
		});
		success();
	} catch (err) {
		error();
	}
}

/**
 * logs user in using a email and password combination
 * @param  {String} email    	email address to attempt signIn with
 * @param  {String} password 	password to attempt signIn with
 * @return {Promise}   			promise of signIn attempt, non-error indicates success
 */
export async function signIn({ email, password }, forceAuthentication) {
	if (!forceAuthentication && isAuthenticated()) {
		return;
	}

	const response = await request('/api/oauth/token', {
		authenticate: false,
		method: 'POST',
		body: {
			grant_type: 'password',
			email,
			password
		}
	});

	// Save user email to cookie
	cookie.save(cookies.userEmail, email, {
		domain,
		secure: process.env.NODE_ENV === 'production',
		path: '/',
		sameSite: 'lax'
	});

	// Include user email with Sentry session
	Sentry.configureScope(scope => {
		scope.setUser({
			email
		});
	});

	const expires = new Date();
	expires.setSeconds(expires.getSeconds() + response.data.expires_in);
	setAccessToken(response.data.access_token, { expires, secure: process.env.NODE_ENV === 'production' });
}

/**
 * sign up a new user account using a name, email and password
 * @param  {String} name     	name of the user signing up
 * @param  {String} email    	email address of the user signing up
 * @param  {String} password 	password of the user signing up
 * @return {Promise}          	promise of registration attempt, non-error indicates success
 */
export async function signUp({ name, email, password, newsletterSubscription, token }) {
	const body = {
		full_name: name,
		email_address: email,
		password
	};

	if (token) {
		body.token = token;
	} else {
		body.newsletter_subscription = newsletterSubscription ? '1' : '0';
	}

	await request('/api/v1/user', {
		authenticate: false,
		method: 'POST',
		body
	});

	// Include user email with Sentry session
	Sentry.configureScope(scope => {
		scope.setUser({
			email
		});
	});

	// Login user after sign up
	await signIn({ email, password });
}

/**
 * reset password for a user account using a token, email and new password
 * @param  {String} token     	reset request token
 * @param  {String} email    	email address
 * @param  {String} password 	new password
 * @return {Promise}          	promise of reset password attempt, non-error indicates success
 */
export async function resetPassword({ token, email, password }) {
	await request('/api/v1/user/reset_forgotten_password', {
		authenticate: false,
		method: 'PUT',
		body: {
			token,
			email_address: email,
			new_password: password
		}
	});

	// Include user email with Sentry session
	Sentry.configureScope(scope => {
		scope.setUser({
			email
		});
	});

	// Login user after password reset
	await signIn({ email, password });
}

/**
 * Logs out current user by removing both tokens and starting a new session
 * @return void
 */
export async function signOut() {
	removeEmailToken();
	removeAccessToken();
	removeSessionToken();

	// Remove user email from Sentry session
	Sentry.configureScope(scope => {
		scope.clear();
	});

	await startNewSession();
}

export default {
	getAccessToken,
	isAuthenticated,
	requiresReauthentication,
	resetPassword,
	signIn,
	signOut,
	signUp
};
